<template>
  <div v-html="about_str"></div>
</template>

<script>
export default {
  name: 'About',
  props: {
    about_str: String
  }
}
</script>
<style>

.back{
  width: 10rem;
  position: relative;
  height: 6.8rem;
  text-align: left;
  background: #0d3349;

}
.about_back_img{
  width: 10rem;
  height: 6.8rem;
  margin: 0rem;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
}

.about_title{
  text-align: center;
  position: absolute;
  bottom: 0rem;
  left: 1rem;
  display: inline-block;
  width: 8rem;
  background: linear-gradient(100deg, rgba(21, 56, 88, 1) -2%, rgba(24, 114, 171, 1) 100%);
  font-size: 0.4rem;
  border-top-left-radius: 0.48rem;
  border-top-right-radius: 0.48rem;
  height: 1rem;
  line-height: 1rem;
  color: #fefefe;
}
.about_title  .about_warn{
  color: #ff9900;
}

.back ul{
  position: absolute;
  padding: 0rem;
  margin: 0rem;
}

.back ul li{
  text-align: center;
  background-color: rgba(19, 64, 96, 0.75);
  color: #fefefe;
  display: inline-block;
  padding: 0.2rem  0.4rem;
  margin: 0rem 0rem 0.2rem 0rem;
  border-radius: 0.5rem;
  font-size: 0.4rem;
}
.back ul.about_data  li{
  position: absolute;
  background-color: rgba(19, 64, 96,1);
}
.about_data_1{
  top:1.8rem;
  left:3.2rem;
  width: 1.7rem;
}
.about_data_2{
  top:2.9rem;
  left:2rem;
  width: 1.6rem;
}
.about_data_3{
  top:2.9rem;
  left:4.8rem;
  width: 2.2rem;
}
.about_data_4{
  top:4.2rem;
  left:1.8rem;
  width: 1.8rem;
}
.about_data_5{
  top:4.2rem;
  left:4.7rem;
  width: 2.6rem;
}

.about_way{
  top: 0.5rem;
  left: 0.5rem;
  width: 6rem;
}
.about_way li{
  float: left;
}
.about_fenxi,.about_gongju{
  top: 0.5rem;
  right: 0.5rem;
  text-align: right;
  width: 4.5rem;
}
.about_fenxi li,.about_gongju li{
  float: right;
}
</style>
